import Input from "../../Input";
import { INPUT_TYPES } from "../../Input";
import Button from "../../Button";
import { ECOLOR, EVARIANTS } from "../../Button/interface";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import { useState,useEffect } from "react";
import { isPositiveNumber } from "../../../utils";
import useCommonStore from "../../../store/store";
import { ToastContainer, toast } from "react-toastify";
import { getUTCDate } from "../../../utils/dateFormatter";
import "react-toastify/dist/ReactToastify.css";



const ManageRate = ({toggleManageRate,roomData:room,setgetRoomList,selectedDate}:any) => {
  const {homestayData} = useCommonStore();
  const [roomData, setRoomData] = useState({
    roomType: room?.roomType,
    adults: room?.adults,
    price: room?.roomPrice,
    breakfast: room?.meals?.breakfast,
    lunch: room?.meals?.lunch,
    dinner: room?.meals?.dinner,
    allMeals: room?.meals?.allMeals,
    extraBedCharge:room?.extraBedCharge,
    maxAdults : room?.maxAdults

  });

  const [error, setError] = useState({
    adults: "",
    price: "",
    breakfast: "",
    lunch: "",
    dinner: "",
    allMeals: "",
    extraBedCharge:"",
    maxAdults:""
  });

  const { data: updateResponse, error:updatePriceError, apiClient: updateRoomPriceClient } = useApi();


  const updateRoomPrice = async () => {
    if (isValidateInput(roomData)) {
      const url = API_ROUTES.PRICE_UPDATE + "/" + roomData?.roomType;
      const options = {
        method: "POST",
        data: {
          isWebApp : true,
          date: getUTCDate(selectedDate),
          meals: {
            breakfast: Number(roomData?.breakfast),
            lunch: Number(roomData?.lunch),
            dinner: Number(roomData?.dinner),
            allMeals: Number(roomData?.allMeals),
          },
          adults: Number(roomData?.adults),
          room: Number(roomData?.price),
          maxAdults: Number(roomData?.maxAdults),
          homeStayId: homestayData?._id,
          extraBedCharge : Number(roomData?.extraBedCharge)

        },
      };
      await updateRoomPriceClient(url, options);
    }
  };

  useEffect(() => {
    if (updateResponse?.status) {
      toggleManageRate(false);
      setgetRoomList(true)
      // setPriceUpdated(true);
    }
  }, [updateResponse]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRoomData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

    const handleCancel = ()=>{
        toggleManageRate(false);
    }

    const isValidateInput = (value: any) => {
      setError({
        adults: "",
        price: "",
        breakfast: "",
        lunch: "",
        dinner: "",
        allMeals: "",
        extraBedCharge:"",
        maxAdults:""
      });
      let isValid = true;
      if (!isPositiveNumber(value?.adults)) {
        setError((prev) => ({ ...prev, adults: "Enter valid adult Value" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.price)) {
        setError((prev) => ({ ...prev, price: "Enter valid price Value" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.lunch)) {
        setError((prev) => ({ ...prev, lunch: "Enter valid lunch rate" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.dinner)) {
        setError((prev) => ({ ...prev, dinner: "Enter valid dinner rate" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.breakfast)) {
        setError((prev) => ({ ...prev, breakfast: "Enter valid breakfast rate" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.allMeals)) {
        setError((prev) => ({ ...prev, allMeals: "Enter valid all meals rate" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.extraBedCharge)) {
        setError((prev) => ({ ...prev, extraBedCharge: "Enter valid all extra bed charge" }));
        isValid = false;
      }
      if (!isPositiveNumber(value?.maxAdults)) {
        setError((prev) => ({ ...prev, maxAdults: "Enter valid all maximum capacity" }));
        isValid = false;
      }
      return isValid;
    };

  return (
       <>
    <div className="flex h-full flex-col md:hidden gap-y-6 mb-[50px] md:mb-0 overflow-y-auto px-3 mt-2 ">
      <div className="flex flex-col gap-y-2">
        <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
          Number of Adults
        </label>
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Number of adults"
          name="adults"
          value={roomData?.adults}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />
        {error?.adults &&  <p className="text-red-600 text-sm font-bold">{error?.adults}</p>}
      </div>

      <div className="flex flex-col gap-y-2">
        <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
          Price
        </label>
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Price"
          name="price"
          value={roomData?.price}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />
        {error?.price &&  <p className="text-red-600 text-sm font-bold">{error?.price}</p>}
      </div>

      <p className="font-['Montserrat'] font-semibold text-[16px] leading-[22px] tracking-[0.2px] text-[#000000] ">
        Meals Price (per person)
      </p>

      <div className="flex flex-col gap-y-2">
        <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
          Breakfast Rate (optional)
        </label>
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Breakfast rate"
          name="breakfast"
          value={roomData?.breakfast}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />
        {error?.breakfast &&  <p className="text-red-600 text-sm font-bold">{error?.breakfast}</p>}
      </div>

      <div className="flex flex-col gap-y-2">
        <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
          Lunch rate (optional)
        </label>
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Lunch rate"
          name="lunch"
          value={roomData?.lunch}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />
        {error?.lunch &&  <p className="text-red-600 text-sm font-bold">{error?.lunch}</p>}
      </div>

      <div className="flex flex-col gap-y-2">
        <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
          Dinner Rate (optional){" "}
        </label>
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Dinner rate"
          name="dinner"
          value={roomData?.dinner}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />
        {error?.dinner &&  <p className="text-red-600 text-sm font-bold">{error?.dinner}</p>}
      </div>

      <div className="flex flex-col gap-y-2">
        <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
          Combined Meal Rate (optional)
        </label>
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Combined meal rate"
          name="allMeals"
          value={roomData?.allMeals}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />
        {error?.allMeals &&  <p className="text-red-600 text-sm font-bold">{error?.allMeals}</p>}
      </div>

      <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Extra Bed Charge
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Extra Bed Charge"
            name="extraBedCharge"
            value={roomData?.extraBedCharge}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.extraBedCharge &&  <p className="text-red-600 text-sm font-bold">{error?.extraBedCharge}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Maximum Adult Capacity          
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Maximum capacity"
            name="maxAdults"
            value={roomData?.maxAdults}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.maxAdults &&  <p className="text-red-600 text-sm font-bold">{error?.maxAdults}</p>}
        </div>

      <div className="flex mt-3 flex-row gap-x-2 justify-between">
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.THREE}
          className="!rounded-[12px] flex-1 !px-[10px] "
          onClick={handleCancel}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#787878]">
            Cancel
          </p>
        </Button>
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.TWO}
          className="!rounded-[12px] flex-1"
          onClick={updateRoomPrice}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]">
            Save Changes
          </p>
        </Button>
      </div>
    </div>

    <div className="hidden h-full md:flex justify-center gap-x-[100px] items-start px-[100px] py-[50px]">
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Number of Adults
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Number of adults"
            name="adults"
            value={roomData?.adults}  
            onChange={onInputChange}          
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.adults &&  <p className="text-red-600 text-sm font-bold">{error?.adults}</p>}

        </div>

        <p className="font-['Montserrat'] font-semibold text-[16px] leading-[22px] tracking-[0.2px] text-[#000000] ">
          Meals Price (per person)
        </p>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Breakfast Rate (optional)
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Breakfast rate"
            name="breakfast"
            value={roomData?.breakfast}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.breakfast &&  <p className="text-red-600 text-sm font-bold">{error?.breakfast}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Lunch rate (optional)
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Lunch rate"
            name="lunch"
            value={roomData?.lunch}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.lunch &&  <p className="text-red-600 text-sm font-bold">{error?.lunch}</p>}

        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Maximum  Capacity          
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Maximum capacity"
            name="maxAdults"
            value={roomData?.maxAdults}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.maxAdults &&  <p className="text-red-600 text-sm font-bold">{error?.maxAdults}</p>}
        </div>
      </div>

      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Price
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Price"
            name="price"
            value={roomData?.price}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.price &&  <p className="text-red-600 text-sm font-bold">{error?.price}</p>}
        </div>
        
        <div className="flex flex-col mt-[48px] gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Dinner Rate (optional){" "}
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Dinner rate"
            name="dinner"
            value={roomData?.dinner}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.dinner &&  <p className="text-red-600 text-sm font-bold">{error?.dinner}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Combined Meal Rate (optional)
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Combined meal rate"
            name="allMeals"
            value={roomData?.allMeals}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.allMeals &&  <p className="text-red-600 text-sm font-bold">{error?.allMeals}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Extra Bed Charge
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Extra Bed Charge"
            name="extraBedCharge"
            value={roomData?.extraBedCharge}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.extraBedCharge &&  <p className="text-red-600 text-sm font-bold">{error?.extraBedCharge}</p>}
        </div>

        
      </div>

    </div>

    <div className="hidden md:flex mt-6 flex-row gap-x-2 items-center justify-center mb-[30px]">
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.THREE}
          className="!rounded-[12px] !px-[60px]"
          onClick={handleCancel}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#787878]">
            Cancel
          </p>
        </Button>
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.TWO}
          className="!rounded-[12px] "
          onClick={updateRoomPrice}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]">
            Save Changes
          </p>
        </Button>
      </div>
      <ToastContainer position="top-center" />

  </>
  );
};

export default ManageRate;
