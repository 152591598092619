const Card = ({
  room,
  handleRoomCategoryChange,
  selectedRoomData 
}: any) => {
  return (
    <>
      <div
        className={`h-full flex md:hidden flex-col cursor-pointer rounded-[16px] w-full shadow-[0px_4px_21px_3px_rgba(0,0,0,0.16)] px-2 py-3 ${
          selectedRoomData?.roomType === room.roomType
            ? "border-2 border-neutral-400 bg-green-200"
            : ""
        }`}
        onClick={() => handleRoomCategoryChange(room)}
      >
        <div className="flex flex-row gap-x-2">
          {/* <div className="h-[120px] w-[88px]">
            <img src={room?.image}></img>
          </div> */}
          <div className="flex w-full flex-col gap-y-3">
            <p className="text-[14px] font-['Montserrat'] font-bold leading-[16px] text-[#222B45]">
              {room?.roomTypeLabel}{" "}
            </p>
            <div className="flex mt-3 flex-row gap-x-3 ">
              <div className="flex flex-col gap-y-2">
                <p className="text-[14px] font-['Montserrat'] font-medium leading-[16px] text-gray-600	">
                  Price: {room.roomPrice}/night
                </p>
                <p className="text-[12px] font-['Montserrat'] font-medium leading-[16px] text-gray-600	">
                  Accomodates: {room?.maxAdults} People
                </p>
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Lunch: {room?.meals?.lunch}
                </p>
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  All Meals: {room?.meals?.allMeals}
                </p>
              </div>

              <div className="flex flex-col gap-y-2">
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Max Capacity: {room?.maxAdults}
                </p>
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Extra Bed Charge: {room?.extraBedCharge}
                </p>
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Breakfast: {room?.meals?.breakfast}
                </p>
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Dinner: {room?.meals?.dinner}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`h-full hidden md:flex flex-row justify-between rounded-[8px] w-full  shadow-[0_4px_21px_3px_rgba(0,0,0,0.16)] px-2 py-3 cursor-pointer  ${
          selectedRoomData?.roomType === room.roomType
            ? "border-2 border-neutral-400 bg-green-200"
            : ""
        }`}
        onClick={() => handleRoomCategoryChange(room)}
      >
        <div className="flex flex-row gap-x-[40px]">
          {/* <div className="h-[186px] w-[223px]">
            <img src={room?.image}></img>
          </div> */}
          <div className="flex items-center flex-col justify-center w-full">
            <p className="text-[14px] self-start text-center font-['Montserrat'] font-bold leading-[16px] text-[#222B45]">
              {room?.roomTypeLabel}
            </p>
            <div className="flex mt-3 flex-row gap-x-3 ">
              <div className="flex flex-col gap-y-2">
                <p className="text-[14px] font-['Montserrat'] font-medium leading-[16px] text-gray-600	">
                  Price: {room.roomPrice}/night
                </p>
                <p className="text-[12px] font-['Montserrat'] font-medium leading-[16px] text-gray-600	">
                  Accomodates: {room?.maxAdults} People
                </p>
              </div>

              <div className="flex flex-col gap-y-2">
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Max Capacity: {room?.maxAdults}
                </p>

                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Extra Bed Charge: {room?.extraBedCharge}
                </p>
              </div>

              <div className="flex flex-col gap-y-2">
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Breakfast: {room?.meals?.breakfast}
                </p>

                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Lunch: {room?.meals?.lunch}
                </p>
              </div>

              <div className="flex flex-col gap-y-2">
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  Dinner: {room?.meals?.dinner}
                </p>

                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-gray-600	">
                  All Meals: {room?.meals?.allMeals}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
