import React, { useState } from "react";

const DaysCheckBox = ({selectedDays,setDays}:any) => {
  const days = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const [checkedDays, setCheckedDays] = useState(
    Array(7).fill(false) 
  );

  const handleCheckboxChange = (index) => {
    const updatedCheckedDays = [...checkedDays];
    updatedCheckedDays[index] = !updatedCheckedDays[index];
    setDays(updatedCheckedDays);
    setCheckedDays(updatedCheckedDays)
  };


  return (
    <div>
      <h3 className="font-bold">Select Days</h3>
      <div className="flex flex-row flex-wrap gap-x-4 border-2  md:gap-x-2 p-2">
        {days.map((day, index) => (
          <label key={index} className="cursor-pointer">
            <input
              type="checkbox"
              checked={checkedDays[index]}
              onChange={() => handleCheckboxChange(index)}
            />
            {day}
          </label>
          
        ))}
      </div>
      {/* <div>
        <h4 className="font-bold">Selected Days:</h4>
        <ul className="font-medium text-grey-500">
          {days.map((day, index) =>
            checkedDays[index] ? <li key={index}>{day}</li> : null
          )}
        </ul>
      </div> */}
    </div>
  );
};

export default DaysCheckBox;
