import Button from "../Button";
import { EVARIANTS, ECOLOR } from "../Button/interface";
import useApi from "../../hooks/useApi";
import { API_ROUTES } from "../../api";
import { useEffect } from "react";
import useCommonStore from "../../store/store";

const Contact = () => {
  const { apiClient: logoutApiClient, ...logoutOptions } = useApi();
  const {
    removeAuthData,
    authData,
    homestayData: activeHomestay,
    isLoggedIn
  } = useCommonStore();

  const handleLogout = async () => {
    await logoutApiClient(API_ROUTES.LOGOUT);
  };

  useEffect(() => {
    if (logoutOptions.data && logoutOptions.data.status) {
      removeAuthData();
      window.location.reload();
    }
  }, [logoutOptions.data]);

  return (
    <div className=" h-full flex items-center justify-center mb-[100px] md:mt-[80px] px-3  w-11/12 md:max-w-[1440px] px-0 lg:px-24 mx-auto sm:my-24">
      <div className="flex flex-col max-w-[700px]  py-3 px-2 md:px-[20px] md:py-[30px] items-center justify-center rounded-[8px] shadow-[0px_4px_4px_4px_rgba(0,0,0,0.15)]">
        <p className="font-bold text-center  text-xl leading-[16px] font-['Montserrat'] text-[#36454F]">
          No Homestay Found</p>
          <p className="font-normal text-center mt-3 text-lg font-['Montserrat'] text-[#808080]">
          Please login with registered mobile number or
          contact at hello@culturetravel.in or +91 9990477711
        </p>
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.TWO}
          className="!rounded-[12px] my-4 w-[200px]"
          onClick={handleLogout}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]">
            Logout
          </p>
        </Button>
      </div>
    </div>
  );
};

export default Contact;
