"use client";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { API_ROUTES } from "../../api";
import useCommonStore from "../../store/store";
import Login from "../Login/v2";
import Button from "../Button/V2";
import EnquiryDetail from "../EnquiryDetail";
import useEnquiryStatus from "../../hooks/useEnquiryStatus"; 
import { useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const UpdateEnquiryStatus = () => {
  const {enquiryId} = useParams();
  const [showLogin, toggleLogin] = useState(false);
  const [homestayEnquiryData, setEnquiryData] = useState(null);
  const [isSubmitting, toggleSubmitting] = useState(false);
  const {authData} = useCommonStore() || {};
  const [statusType, setStatus] = useState('');
  const { data: updateEnquiryResponse, apiClient: updateEnquiryApiClient } =  useApi();
  const { enquiryData, getHomestayEnquiry } = useEnquiryStatus({ enquiryId });

    const handleEnquiryUpdate = async (value: string) => {
        if (authData?.data?.phone === homestayEnquiryData?.homeStayId?.owner.phone) {
            toggleSubmitting(true);
            const url = API_ROUTES.UPDATE_ENQUIRY_STATUS;
            const config = {
                method: "PATCH",
                data: { value, enquiryId },
            };
            await updateEnquiryApiClient(url, config);
            setStatus(value);

        } else {
            toast.warning("Please Login with registerd phone number.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    useEffect(() => {
        if (updateEnquiryResponse?.status === true) {
            toggleSubmitting(false);
        }
    }, [updateEnquiryResponse]);

    useEffect(() => {
        if (!authData?.data?.phone) {
            toggleLogin(true);

        } else {
            toggleLogin(false);

        }
    }, [authData]);

    useEffect(() => {
        if (enquiryData) {
            setEnquiryData(enquiryData);
            if(authData?.data?.phone)
            setStatus(enquiryData.status);
        }
    }, [enquiryData]);

    useEffect(() => {
        getHomestayEnquiry();
    }, []);


  return (
    <div className="w-full md:max-w-[1440px] items-center justify-center px-2  mx-auto md:my-[120px] mt-[30px]">
      {statusType !== "" ? (
        <div className="flex items-center justify-center md:mt-[60px] border-2 shadow shadow-md p-3 md:w-[700px] w-full h-full rounded-lg mx-auto">
          {(statusType == "confirmed" || statusType === "rejected") && (
            <div className="flex  flex-col gap-y-3 items-center justify-center">
              <p className="text-teal-600 self-center font-medium text-lg">
                Your action submitted successfully!
              </p>
              <EnquiryDetail
                statusType={statusType}
                homestayEnquiryData={homestayEnquiryData}
              />
            </div>
          )}

          {statusType === "requested" && (
            <div className="flex  items-center gap-y-10 justify-center flex-col">
              <p className="md:text-[30px] text-[16px] font-semibold text-[#484848] font-['Montserrat'] self-center">
                Please Confirm or Reject Booking
              </p>
              <EnquiryDetail
                statusType={statusType}
                homestayEnquiryData={homestayEnquiryData}
              />
              <div className="flex items-center gap-x-6 justify-between">
                <Button
                  onClick={() => handleEnquiryUpdate("rejected")}
                  className="!rounded-full !px-8 !py-2 !bg-[#F75D37]"
                >
                  <p>Reject</p>
                </Button>

                <Button
                  className="!rounded-full !px-8  !bg-[#28B281] !py-2 !text-white"
                  onClick={() => handleEnquiryUpdate("confirmed")}
                >
                  <p>Confirm</p>
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : <div className="w-full mx-auto flex items-center rounded-lg justify-center md:max-w-[500px] shadow-[0px_0px_4px_2px_rgba(0,0,0,0.15)] py-4"><p className="font-['Montserrat'] font-semibold text-xl leading-[14px] text-[#696969]">Enquiry not available</p></div>}

      {showLogin && (
        <div className="md:max-w-[1440px]  px-0 md:px-24 md:mx-auto">
          <div className="relative h-full justify-center items-center md:mt-[20px] flex flex-col">
            <Login />
          </div>
        </div>
      )}
      <ToastContainer autoClose={1000} />
    </div>
  );
};

export default UpdateEnquiryStatus;
