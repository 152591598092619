import BookingDetail from "./Card/BookingDetail";
import Modal from "../Modal";
import { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import { API_ROUTES } from "../../api";
import moment from "moment";
import BookingStats from "./BookingStats";
import UpcomingBookings from "./UpcomingBooking";
import NewBookings from "./NewBookings";
import PastBookings from "./PastBookings";
import CancelledBookings from "./CancelledBookings";
import SingleDayPicker from "../Calendar";
import { getUTCDate } from "../../utils/dateFormatter";
import VideoModal from "../Modal/v2";


const Booking = () => {
  const [showBookingDetail, setBookingDetail] = useState(null);
  const [selectedDate, setChangeDate] = useState(new Date());
  const [activeTab, toggleTab] = useState("upcoming");
  const [showTutorial,toggleTutorial] = useState(false);

  const [bookingData, setBookingData] = useState(null);

  const { data: bookingStats, error, loading,apiClient: bookingStatsClient } = useApi();

  const handleClose = () => {
    setBookingDetail(null);
  };

  const getBookingStats = async () => {
    const url = API_ROUTES.BOOKING_STATS;
    const options = {
      params: {
        date: getUTCDate(selectedDate),
      },
    };
    await bookingStatsClient(url, options);
  };

  useEffect(() => {
    getBookingStats();
  }, [selectedDate]);

  useEffect(() => {
    if (bookingStats?.data) {
      setBookingData(bookingStats?.data);
    }
  }, [bookingStats]);

  const handleDateChange = (newDate) => {
    setChangeDate(newDate);
  };

  const handleBookingDetail = (bookingData:any)=>{
    setBookingDetail(bookingData);
  }

  const handleTutorial=()=>{
    toggleTutorial(true);
  }

  return (
    <>
      <div className="px-3 my-[100px] w-11/12 md:max-w-[1440px] px-0 lg:px-24 mx-auto sm:my-[50px]">
        {/* <p className="cursor-pointer" onClick={handleTutorial}>Tutorial</p> */}
        <div className="w-full my-3 flex items-center justify-center">
          <SingleDayPicker value={selectedDate} handleDateChange={handleDateChange}/>
        </div>
        <BookingStats
          selectedDate={selectedDate}
          activeTab={activeTab}
          toggleTab={toggleTab}
        />

        {activeTab === "upcoming" && <UpcomingBookings handleBookingDetail={handleBookingDetail} selectedDate={selectedDate} />}
        {activeTab === "new" && <NewBookings handleBookingDetail={handleBookingDetail}  selectedDate={selectedDate} />}
        {activeTab === "past" && <PastBookings handleBookingDetail={handleBookingDetail}  selectedDate={selectedDate} />}
        {activeTab === "cancelled" && <CancelledBookings handleBookingDetail={handleBookingDetail}  selectedDate={selectedDate} />}

        {showBookingDetail && (
          <Modal
            isOpen={showBookingDetail}
            onClose={handleClose}
            showCloseIcon={true}
            contentClass="!py-[20px] !mb-0 rounded-[30px]"
            containerClass="rounded-[11px]"
          >
            <BookingDetail bookingDetail={showBookingDetail} />
          </Modal>
        )}

        {
          showTutorial && <VideoModal  show={showTutorial}
          title="Change Room Occupancy"
          onClose={() => toggleTutorial(false)}>
                <iframe className="w-full md:w-[720px] h-[420px]" src="https://www.youtube.com/embed/k0HOHPb9zMg?si=-wg_P2DdAmVUdjiR" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

          </VideoModal>
        }
      </div>
    </>
  );
};

export default Booking;
